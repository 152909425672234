<template>
  <div class="product-page">
    <div class="product-content">
      <v-container>
        <v-breadcrumbs :items="breadcrumbs"></v-breadcrumbs>

        <v-row>
          <v-col cols="12" lg="8">
            <div class="mainPreview">
              <v-progress-circular
                v-if="!iframeLoaded"
                class="centerAbsolute"
                indeterminate
                color="primary"
              ></v-progress-circular>
              <iframe
                id="matterportProject"
                :title="details.title"
                width="100%"
                height="100%"
                :src="details.source"
                frameborder="0"
                style="overflow: hidden; height: calc(100vh - 144px); width: 100%"
                allowfullscreen
                allow="xr-spatial-tracking"
                sandbox="allow-same-origin allow-scripts allow-popups allow-forms"
              ></iframe>
            </div>
          </v-col>
          <v-col cols="12" lg="4">
            <h2 class="gs_reveal">{{ details.title }}</h2>
            <p class="mt-3 mb-7 gs_reveal">{{ details.description }}</p>
            <v-divider class="gs_reveal" />
            <div class="information-text">
              <div class="my-10">
                <h3 class="mb-5 gs_reveal">Specification</h3>
                <v-simple-table class="gs_reveal">
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-left">Property</th>
                        <th class="text-left">Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="item in specification" :key="item.property">
                        <td>{{ item.property }}</td>
                        <td>{{ item.value }}</td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </div>
            </div>
            <div class="gs_reveal">
              <v-btn block class="mr-5 my-10" color="primary" to="/request-a-quote">Request a Quote</v-btn>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <Footer />
  </div>
</template>

<script>
import Footer from '@/components/Footer';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

export default {
  components: {
    Footer,
  },
  data: () => ({
    iframeLoaded: false,
  }),
  computed: {
    currentProject() {
      const currentPath = this.$route.params.id;
      const projectList = this.$store.getters.getProjectList;
      const currentProject = projectList.filter((x) => x.path === currentPath)[0] || [];
      return currentProject;
    },

    details() {
      const data = {
        title: this.currentProject.titleLong,
        description: this.currentProject.description,
        source: this.currentProject.source,
      };
      return data;
    },

    specification() {
      const data = [
        {
          property: 'Building Type',
          value: this.currentProject.specification?.type,
        },
        {
          property: 'Building Area',
          value: `${this.currentProject.specification?.area} m2`,
        },
        {
          property: 'Number of Rooms',
          value: this.currentProject.specification?.rooms,
        },
      ];
      return data;
    },

    breadcrumbs() {
      const data = [
        {
          text: 'Home',
          disabled: false,
          to: '/',
        },
        {
          text: this.currentProject.title,
          disabled: true,
          to: `/project/${this.currentProject.path}`,
        },
      ];
      return data;
    },
  },
  methods: {
    animateFrom(elem, dir) {
      const direction = dir || 1;
      let x = 0;
      let y = direction * 100;
      const element = elem;
      if (element.classList.contains('gs_reveal_fromLeft')) {
        x = -300;
        y = 0;
      } else if (element.classList.contains('gs_reveal_fromRight')) {
        x = 300;
        y = 0;
      }
      element.style.transform = `translate(${x}px, ${y}px)`;
      element.style.opacity = '0';
      gsap.fromTo(
        element,
        { x, y, autoAlpha: 0 },
        {
          duration: 2.25,
          x: 0,
          y: 0,
          autoAlpha: 1,
          ease: 'expo',
          overwrite: 'auto',
        }
      );
    },

    hide(elem) {
      gsap.set(elem, { autoAlpha: 0 });
    },
  },

  mounted() {
    window.scrollTo(0, 0);

    document.querySelector('iframe').onload = () => {
      this.iframeLoaded = true;
    };

    gsap.registerPlugin(ScrollTrigger);

    gsap.utils.toArray('.gs_reveal').forEach((elem) => {
      this.hide(elem); // assure that the element is hidden when scrolled into view

      ScrollTrigger.create({
        trigger: elem,
        onEnter: () => {
          this.animateFrom(elem);
        },
        onEnterBack: () => {
          this.animateFrom(elem, -1);
        },
        onLeave: () => {
          this.hide(elem);
        }, // assure that the element is hidden when scrolled into view
      });
    });
  },
};
</script>

<style>
.container {
  position: relative;
}

.corn-bg {
  position: absolute;
  bottom: -54px;
  right: -225px;
  max-width: 70%;
  opacity: 0.8 !important;
}

/* Styles from the GreenSock website */
.product-content img {
  max-width: 100%;
}
.gs_reveal {
  opacity: 0;
  visibility: hidden;
  will-change: transform, opacity;
}
/* GreenSock end */

.information-text {
  position: relative;
  z-index: 1;
}

.centerAbsolute {
  position: absolute;
  top: 50%;
  left: 50%;
}

.mainPreview {
  position: relative;
  height: calc(100vh - 144px);
  width: 100%;
}

@media (max-width: 1264px) {
  .product-page .product-content .pl-12 {
    padding-left: 12px !important;
  }
  .corn-bg {
    right: -125px;
  }
}

@media (max-width: 595px) {
  .product-page .product-content .my-10 {
    margin-top: 24px !important;
    margin-bottom: 12px !important;
  }
  .product-page .product-content .mb-5 {
    margin-top: 12px !important;
    margin-bottom: 12px !important;
  }
  .product-page .product-content .v-btn {
    margin-top: 24px !important;
  }
  .corn-bg {
    right: -75px;
  }
}
</style>
